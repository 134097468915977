
      module.exports = {
        functional: true,
        render(_h, _vm) {
          const { _c, _v, data, children = [] } = _vm;

          const {
            class: classNames,
            staticClass,
            style,
            staticStyle,
            attrs = {},
            ...rest
          } = data;

          return _c(
            'svg',
            {
              class: [classNames,staticClass],
              style: [style,staticStyle],
              attrs: Object.assign({"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 512 512"}, attrs),
              ...rest,
            },
            children.concat([_c('path',{attrs:{"d":"M142.398 415.996C127.734 474.473 105.25 512 80 512 35.816 512 0 397.385 0 256S35.816 0 80 0c32.414 0 60.258 61.795 72.828 150.475-10.215 11.234-11.781 28.14-2.203 40.593a31.1 31.1 0 007.18 6.272C159.19 216.207 160 235.78 160 256a768.851 768.851 0 01-5.867 95.994H128a32.001 32.001 0 000 64.002z","fill":"currentColor","opacity":".4"}}),_c('path',{attrs:{"d":"M368 96.002a48.001 48.001 0 10-48-48 48.002 48.002 0 0048 48zM209.687 317.479l-14.812 34.515H128a32.001 32.001 0 000 64.002h77.438a47.924 47.924 0 0044.125-29.078l8.78-20.533-10.656-6.297a95.386 95.386 0 01-38-42.61zM480 223.99h-44.031l-26.063-53.252c-12.5-25.547-35.469-44.218-61.781-50.937l-71.063-21.143a95.69 95.69 0 00-80.875 17.143l-39.625 30.406A31.996 31.996 0 10195.5 196.99l39.688-30.408c7.656-5.89 17.437-8 25.25-6.14l14.687 4.374-37.438 87.395A64.16 64.16 0 00264 332.525l84.938 50.174-27.438 87.72a31.969 31.969 0 0020.938 40.097 31.942 31.942 0 0040.125-20.971l31.625-101.065a48.16 48.16 0 00-21.625-54.392l-61.25-36.143 31.312-78.283 20.281 41.44A48.269 48.269 0 00426 287.992h54a32.001 32.001 0 000-64.002z","fill":"currentColor"}})])
          )
        }
      }
    